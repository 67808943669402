<template>
  <b-sidebar
    id="add-new-contact-sidebar"
    :visible="isAddNewContactSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-contact-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Supplier
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
            ref="refFormObserver"
            #default="{invalid}"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="addNewContact"
          @reset.prevent="resetForm"
        >
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name *"
                label-for="Name"
              >
                <b-form-input
                  id="ContactName"
                  v-model="ContactName"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Name"
                  v-uppercase
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Category -->
            <validation-provider
              #default="validationContext"
              name="Category"
              rules="required"
            >
              <b-form-group
                label="Category *"
                label-for="Category"
              >
                <b-form-input
                  id="ContactCategory"
                  v-model="ContactCategory"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Category"
                  v-uppercase
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Type -->
            <validation-provider
              #default="validationContext"
              name="Type"
              rules="required"
            >
              <b-form-group
                label="Type *"
                label-for="Type"
              >
                <b-form-input
                  id="ContactType"
                  v-model="ContactType"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Type"
                  v-uppercase
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Address -->
            <validation-provider
              #default="validationContext"
              name="Address"
              rules="required"
            >
              <b-form-group
                label="Address *"
                label-for="ContactAddress"
              >
                <b-form-input
                  id="ContactAddress"
                  v-model="ContactAddress"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Address"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- City -->
            <validation-provider
              #default="validationContext"
              name="City"
              rules="required"
            >
              <b-form-group
                label="City *"
                label-for="ContactCity"
              >
                <b-form-input
                  id="ContactCitys"
                  v-model="ContactCity"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="City"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Postal Code -->
            <validation-provider
              #default="validationContext"
              name="Postal Code"
              rules="required"
            >
              <b-form-group
                label="Postal Code *"
                label-for="ContactPostalCode"
              >
                <b-form-input
                  id="ContactPostalCode"
                  v-model="ContactPostalCode"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Postal Code"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Phone 1 -->
            <validation-provider
              #default="validationContext"
              name="Phone1"
              rules="required"
            >
              <b-form-group
                label="Phone 1 *"
                label-for="Phone1"
              >
                <b-form-input
                  id="Phone1"
                  v-model="ContactPhone1"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Phone 1"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Phone 2 -->
            <validation-provider
              #default="validationContext"
              name="Phone2"
            >
              <b-form-group
                label="Phone 2"
                label-for="Phone2"
              >
                <b-form-input
                  id="Phone2"
                  v-model="ContactPhone2"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Phone 2"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Fax -->
            <validation-provider
              #default="validationContext"
              name="Fax"
            >
              <b-form-group
                label="Fax"
                label-for="Fax"
              >
                <b-form-input
                  id="Fax"
                  v-model="ContactFax"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Fax"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
            >
              <b-form-group
                label="Email"
                label-for="Email"
              >
                <b-form-input
                  id="Email"
                  v-model="ContactEmail"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Email"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- PIC Name -->
            <validation-provider
              #default="validationContext"
              name="PicName"
              rules="required"
            >
              <b-form-group
                label="PIC Name *"
                label-for="PicName"
              >
                <b-form-input
                  id="PicName"
                  v-model="ContactPicName"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="PIC Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- PIC Phone -->
            <validation-provider
              #default="validationContext"
              name="PicPhone"
              rules="required"
            >
              <b-form-group
                label="PIC Phone *"
                label-for="PicPhone"
              >
                <b-form-input
                  id="PicPhone"
                  v-model="ContactPicPhone"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="PIC Phone"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Bank 1 -->
            <validation-provider
              #default="validationContext"
              name="Bank1"
              rules="required"
            >
              <b-form-group
                label="Bank 1 *"
                label-for="Bank1"
              >
                <b-form-input
                  id="Bank1"
                  v-model="ContactBank1"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Bank 1"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Bank 2 -->
            <validation-provider
              #default="validationContext"
              name="Bank2"
            >
              <b-form-group
                label="Bank 2"
                label-for="Bank2"
              >
                <b-form-input
                  id="Bank2"
                  v-model="ContactBank2"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Bank 2"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Bank 3 -->
            <validation-provider
              #default="validationContext"
              name="Bank3"
            >
              <b-form-group
                label="Bank 3"
                label-for="Bank3"
              >
                <b-form-input
                  id="Bank3"
                  v-model="ContactBank3"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Bank 3"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
                block
                :disabled="invalid"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              :disabled="submitButtonDisabled"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { ref } from '@vue/composition-api'

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import useContactsList from './useContactsList'

const ContactName = ""
const ContactAddress = ""
const ContactCity = ""
const ContactPostalCode = ""
const ContactPhone1 = ""
const ContactPhone2 = ""
const ContactFax = ""
const ContactEmail = ""
const ContactPicName = ""
const ContactPicPhone = ""
const ContactBank1 = ""
const ContactBank2 = ""
const ContactBank3 = ""
const submitButtonDisabled = false
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewContactSidebarActive',
    event: 'update:is-add-new-contact-sidebar-active',
  },
  props: {
    isAddNewContactSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      submitButtonDisabled,
      required,
      alphaNum,
      ContactName,
      ContactAddress,
      ContactCity,
      ContactPostalCode,
      ContactPhone1,
      ContactPhone2,
      ContactFax,
      ContactEmail,
      ContactPicName,
      ContactPicPhone,
      ContactBank1,
      ContactBank2,
      ContactBank3,
    }
  },
  setup() {
    const blankContactData = {
      ContactName: '',
      ContactAddress: '',
      ContactCity: '',
      ContactPostalCode: '',
      ContactPhone1: '',
      ContactPhone2: '',
      ContactFax: '',
      ContactEmail: '',
      ContactPicName: '',
      ContactPicPhone: '',
      ContactBank1: '',
      ContactBank2: '',
      ContactBank3: '',
    }
    const unitData = ref(blankContactData)
    const resetunitData = () => {
      unitData.value = blankContactData
    }

    const onSubmit = () => {
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetunitData)

    return {
      ContactName,
      ContactAddress,
      ContactCity,
      ContactPostalCode,
      ContactPhone1,
      ContactPhone2,
      ContactFax,
      ContactEmail,
      ContactPicName,
      ContactPicPhone,
      ContactBank1,
      ContactBank2,
      ContactBank3,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  mounted() {
  },
  methods: {
    addNewContact() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const payload = {
            contact_name: this.ContactName,
            contact_address: this.ContactAddress,
            contact_category: this.ContactCategory,
            contact_type: this.ContactType,
            contact_city: this.ContactCity,
            contact_postal_code: this.ContactPostalCode,
            contact_phone_1: this.ContactPhone1,
            contact_phone_2: this.ContactPhone2,
            contact_fax: this.ContactFax,
            contact_email: this.ContactEmail,
            contact_pic_name: this.ContactPicName,
            contact_pic_phone: this.ContactPicPhone,
            contact_bank_1: this.ContactBank1,
            contact_bank_2: this.ContactBank2,
            contact_bank_3: this.ContactBank3,
            time: moment(),
          }
          const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_CONTACT}`
          axios
          .post(url, payload, { headers })
          .then(response => {
            this.submitButtonDisabled = false
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          if (response.data.success === true) {
                this.$root.$emit('refreshTable', 'refreshTable')
                this.isAddNewContactSidebarActive = false
                this.ContactName = ""
                this.ContactAddress = ""
                this.ContactCity = ""
                this.ContactPostalCode = ""
                this.ContactPhone1 = ""
                this.ContactPhone2 = ""
                this.ContactFax = ""
                this.ContactEmail = ""
                this.ContactPicName = ""
                this.ContactPicPhone = ""
                this.ContactBank1 = ""
                this.ContactBank2 = ""
                this.ContactBank3 = ""
                // this.$parent.refContactListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Your data has been inserted!',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              }
              }).catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-contact-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
