<template>

  <div>

    <contact-list-add-new
      :is-add-new-contact-sidebar-active.sync="isAddNewContactSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />
    <contact-list-edit
      :is-edit-contact-sidebar-active.sync="isEditSupplierSidebarActive"
      :contact-id.sync="ContactID"
      :contact-name.sync="ContactName"
      :contact-category.sync="ContactCategory"
      :contact-type.sync="ContactType"
      :contact-address.sync="ContactAddress"
      :contact-city.sync="ContactCity"
      :contact-postal-code.sync="ContactPostalCode"
      :contact-phone1.sync="ContactPhone1"
      :contact-phone2.sync="ContactPhone2"
      :contact-fax.sync="ContactFax"
      :contact-email.sync="ContactEmail"
      :contact-pic-name.sync="ContactPicName"
      :contact-pic-phone.sync="ContactPicPhone"
      :contact-bank1.sync="ContactBank1"
      :contact-bank2.sync="ContactBank2"
      :contact-bank3.sync="ContactBank3"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <input
                id="fileUpload"
                name="fileUpload"
                ref="file"
                type="file"
                accept=".xlsx, .xls, .csv"
                @change="uploadFile()"
                hidden
              />
              <b-button
                variant="success"
                class="d-inline-block mr-1"
                v-if="userRole=='admin' || userRole=='super-admin'"
                @click="chooseFile()"
              >
                <span class="text-nowrap">Import</span>
              </b-button>
              &nbsp;
              <b-button
                variant="primary"
                @click="isAddNewContactSidebarActive = true"

                v-if="userRole=='admin' || userRole=='super-admin'"
              >
                <span class="text-nowrap">Add Supplier</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refContactListTable"
        class="position-relative"
        :items="fetchContacts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Name -->
        <template #cell(contact_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.contact_name }}</span>
          </div>
        </template>

        <!-- Column: City -->
        <template #cell(contact_city)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.contact_city }}</span>
          </div>
        </template>

        <!-- Column: Fax -->
        <template #cell(contact_fax)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.contact_fax }}</span>
          </div>
        </template>

        <!-- Column: Email -->
        <template #cell(contact_email)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.contact_email }}</span>
          </div>
        </template>

        <!-- Column: Phones -->
        <template #cell(contact_phone_1)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.contact_phone_1 }} / {{ data.item.contact_phone_2 }}</span>
          </div>
        </template>

        <!-- Column: DateCreated -->
        <template #cell(contact_created_time)="data">
            {{ dateSimple(data.item.contact_created_time, 'YYYY-MM-DD HH:mm:ss') }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editContact(  data.item )">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showDetail( data.item )">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteContact( data.item.contact_id )">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalContactes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import axios from 'axios'
import moment from 'moment'
import vSelect from 'vue-select'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
// import ContactesListFilters from './ContactesListFilters.vue'
import ContactList from './ContactList'

import contactsModule from '../contactsModule'
import ContactListAddNew from './ContactAddNew.vue'
import ContactListEdit from './ContactEdit.vue'

const userRole = ''
const ContactData = null
export default {
  components: {
    // ContactesListFilters,
    ContactListEdit,
    ContactListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    this.userRole = this.$cookies.get('UserRole')
    this.$root.$on('refreshTable', text => {
    console.log(text) // here you need to use the arrow function
    this.$refs.refContactListTable.refresh()
  })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-unit'

    // Register module
    if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.registerModule(BRANCH_APP_STORE_MODULE_NAME, contactsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
    })

    const isAddNewContactSidebarActive = ref(false)
    const isEditSupplierSidebarActive = ref(false)
    const ContactID = ref('')
    const ContactName = ref('')
    const ContactCategory = ref('')
    const ContactType = ref('')
    const ContactAddress = ref('')
    const ContactCity = ref('')
    const ContactPostalCode = ref('')
    const ContactPhone1 = ref('')
    const ContactPhone2 = ref('')
    const ContactFax = ref('')
    const ContactEmail = ref('')
    const ContactPicName = ref('')
    const ContactPicPhone = ref('')
    const ContactBank1 = ref('')
    const ContactBank2 = ref('')
    const ContactBank3 = ref('')
    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchContacts,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalContactes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContactListTable,
      refetchData,

      // UI
      resolveContactRoleVariant,
      resolveContactRoleIcon,
      resolveContactStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = ContactList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    return {

      // Sidebar
      ContactID,
      ContactName,
      ContactCategory,
      ContactType,
      ContactAddress,
      ContactCity,
      ContactPostalCode,
      ContactPhone1,
      ContactPhone2,
      ContactFax,
      ContactEmail,
      ContactPicName,
      ContactPicPhone,
      ContactBank1,
      ContactBank2,
      ContactBank3,
      isEditSupplierSidebarActive,
      isAddNewContactSidebarActive,
      userRole,
      ContactData,
      fetchContacts,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalContactes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContactListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveContactRoleVariant,
      resolveContactRoleIcon,
      resolveContactStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {

    uploadFile() {
      const firstFile = this.$refs.file.files[0]
      this.file = firstFile
      const userToken = this.$cookies.get("userToken")
      const header = {
        "Content-Type": "multipart/form-dataitem",
        Authorization: `Bearer ${userToken}`,
      }
      const formData = new FormData()
      if (typeof firstFile !== "undefined") {
        formData.append("file", firstFile, firstFile.name)
        formData.append("time", moment())
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_IMPORT_CONTACTS}`
        axios.post(url, formData, { headers: header }).then(response => {
          if (response.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Import Success",
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Import Failed, Something went wrong on the server",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Import Failed",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      }
    },
    chooseFile() {
      document.getElementById("fileUpload").click()
    },
      deleteContact(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_CONTACT}${id}`
          axios
              .get(url, { headers })
              .then(response => {
                console.log(response)
                if (response.data.success === true) {
                  this.$refs.refContactListTable.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been deleted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      editContact(data) {
        const parsed = data
        this.ContactID = parsed.contact_id
        this.ContactName = parsed.contact_name
        this.ContactAddress = parsed.contact_address
        this.ContactCategory = parsed.contact_category
        this.ContactType = parsed.contact_type
        this.ContactCity = parsed.contact_city
        this.ContactPostalCode = parsed.contact_postal_code
        this.ContactPhone1 = parsed.contact_phone_1
        this.ContactPhone2 = parsed.contact_phone_2
        this.ContactFax = parsed.contact_fax
        this.ContactEmail = parsed.contact_email
        this.ContactPicName = parsed.contact_pic_name
        this.ContactPicPhone = parsed.contact_pic_phone
        this.ContactBank1 = parsed.contact_bank_1
        this.ContactBank2 = parsed.contact_bank_2
        this.ContactBank3 = parsed.contact_bank_3
          this.isEditSupplierSidebarActive = true
      },
      showDetail(item) {
        this.$cookies.set('contactDetail', item)
        this.$router.push({ name: 'apps-supplier-detail' })
      },
    dateSimple(value, format = 'YYYY-MM-DD') {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else { dateRet = null }
        return dateRet
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
