import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'

export default function useUnitsList() {
  const toast = useToast()
  const refContactListTable = ref(null)
  const tableColumns = [
    { key: 'contact_name', sortable: true },
    { key: 'contact_city', sortable: true },
    { key: 'contact_phone_1', sortable: true },
    { key: 'contact_fax', sortable: true },
    { key: 'contact_email', sortable: true },
    { key: 'contact_created_time', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalContactes = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('contact_name')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refContactListTable.value ? refContactListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContactes.value,
    }
  })

  const refetchData = () => {
    refContactListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, isSortDirDesc], () => {
    refetchData()
  })

  const fetchContacts = (ctx, callback) => {
  const to = perPage.value * (currentPage.value - 1)
  const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_CONTACTS}`, {
        headers,
        params: {
          search: searchQuery.value,
          order_by: sortBy.value,
          order_sort: isSortDirDesc.value ? 'DESC' : 'ASC',
          offset: to,
          limit: perPage.value,
        },
      })
      .then(response => {
        totalContactes.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchContacts,
    tableColumns,
    perPage,
    currentPage,
    totalContactes,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refContactListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
